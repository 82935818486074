.videoModal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
}

.wrapper {
  padding-bottom: 56.25%;
}

.player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 2;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  cursor: pointer;
}
.closeIcon {
  font-size: 36px;
}

@media only screen and (max-width: 768px) {
  .player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 90%;
    height: 90%;
    z-index: 2;
  }
  .wrapper {
    position: relative;
  }
}

@media screen and (min-aspect-ratio: 1280/720) {
  .player {
    // position: absolute;
    // top: 35%;
    // left: 50%;
    // transform: translate(-50%, -35%);
    // width: 80%;
    // height: 80%;
    // z-index: 2;
  }
}
