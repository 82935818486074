/* ThemeStyles.css - Scoped to 2025 only */

/* Scoping selectors for 2025 theme */
.theme-active .theme-container,
.theme-container,
.theme-scrollbars,
.theme-active .theme-scrollbars {
  /* Base styles cascade to all children */
}

:root {
  /* Light mode (default) variables for 2025 */
  --theme-lime: #DAFF01;
  --theme-lavender: #9167F0;
  --theme-black: #000000;
  --theme-white: #ffffff;
  --theme-gray: #ededed;

  /* Light mode theme properties */
  --theme-bg-primary: var(--theme-gray);
  --theme-bg-secondary: #ededed;
  --theme-text-primary: var(--theme-black);
  --theme-text-secondary: #444444;
  --theme-header-bg: var(--theme-white);  /* White header in light mode */
  --theme-header-text: var(--theme-black); /* Black text in light mode */
  --theme-announcement-bg: var(--theme-lime);
  --theme-announcement-text: var(--theme-black);
  --theme-scrollbar-thumb: var(--theme-lavender);
}

/* Dark mode styles - Add the theme-active selector to increase specificity */
.theme-active [data-theme='dark'],
[data-theme='dark'] {
  /* Dark mode theme properties */
  --theme-bg-primary: var(--theme-black);
  --theme-bg-secondary: #121212;
  --theme-text-primary: var(--theme-white);
  --theme-text-secondary: #cccccc;
  --theme-header-bg: var(--theme-black); /* Black header in dark mode */
  --theme-header-text: var(--theme-white); /* White text in dark mode */
  --theme-announcement-bg: var(--theme-black);
  --theme-announcement-text: var(--theme-lime);
  --theme-scrollbar-thumb: var(--theme-lime);
}

/* Utility classes - Scope them to 2025 containers and scrollbars */
.theme-active .theme-bg-primary,
.theme-container .theme-bg-primary,
.theme-scrollbars .theme-bg-primary,
.theme-bg-primary {
  background-color: var(--theme-bg-primary);
}

.theme-active .theme-bg-secondary,
.theme-container .theme-bg-secondary,
.theme-scrollbars .theme-bg-secondary,
.theme-bg-secondary {
  background-color: var(--theme-bg-secondary);
}

.theme-active .theme-bg-black,
.theme-container .theme-bg-black,
.theme-scrollbars .theme-bg-black,
.theme-bg-black {
  background-color: var(--theme-black);
}

.theme-active .theme-bg-lime,
.theme-container .theme-bg-lime,
.theme-scrollbars .theme-bg-lime,
.theme-bg-lime {
  background-color: var(--theme-lime);
}

.theme-active .theme-bg-lavender,
.theme-container .theme-bg-lavender,
.theme-scrollbars .theme-bg-lavender,
.theme-bg-lavender {
  background-color: var(--theme-lavender);
}

.theme-active .theme-bg-header,
.theme-container .theme-bg-header,
.theme-scrollbars .theme-bg-header,
.theme-bg-header {
  background-color: var(--theme-header-bg);
}

.theme-active .theme-bg-announcement,
.theme-container .theme-bg-announcement,
.theme-scrollbars .theme-bg-announcement,
.theme-bg-announcement {
  background-color: var(--theme-announcement-bg);
}

.theme-active .theme-text-primary,
.theme-container .theme-text-primary,
.theme-scrollbars .theme-text-primary,
.theme-text-primary {
  color: var(--theme-text-primary);
}

.theme-active .theme-text-secondary,
.theme-container .theme-text-secondary,
.theme-scrollbars .theme-text-secondary,
.theme-text-secondary {
  color: var(--theme-text-secondary);
}

.theme-active .theme-text-lime,
.theme-container .theme-text-lime,
.theme-scrollbars .theme-text-lime,
.theme-text-lime {
  color: var(--theme-lime);
}

.theme-active .theme-text-lavender,
.theme-container .theme-text-lavender,
.theme-scrollbars .theme-text-lavender,
.theme-text-lavender {
  color: var(--theme-lavender);
}

.theme-active .theme-text-black,
.theme-container .theme-text-black,
.theme-scrollbars .theme-text-black,
.theme-text-black {
  color: var(--theme-black);
}

.theme-active .theme-text-white,
.theme-container .theme-text-white,
.theme-scrollbars .theme-text-white,
.theme-text-white {
  color: var(--theme-white);
}

.theme-active .theme-text-announcement,
.theme-container .theme-text-announcement,
.theme-scrollbars .theme-text-announcement,
.theme-text-announcement {
  color: var(--theme-announcement-text);
}

.theme-active .theme-transition,
.theme-container .theme-transition,
.theme-scrollbars .theme-transition,
.theme-transition {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Wavy text link styles */
.wavy-text-link {
  position: relative;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 6px !important;
  display: inline-block;
  padding-bottom: 0.2rem;
  z-index: 1;
  transition: color 0.3s ease, text-decoration-color 0.3s ease;
}

[data-theme='dark'] .wavy-text-link::before,
:root:not([data-theme='dark']) .wavy-text-link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0; /* Changed from -2px to 0 */
  width: 0%;
  height: 85%; /* Reduced from 92% */
  transition: width 0.3s ease;
  z-index: -1;
}

/* Dark mode background color */
[data-theme='dark'] .wavy-text-link::before {
  background-color: var(--theme-lime);
}

/* Light mode background color */
:root:not([data-theme='dark']) .wavy-text-link::before {
  background-color: var(--theme-lavender);
}

:root:not([data-theme='dark']) .wavy-text-link:hover::before {
  width: 100%;
}

[data-theme='dark'] .wavy-text-link:hover::before {
  width: 100%;
}

/* Light mode specific styles */
:root:not([data-theme='dark']) .wavy-text-link {
  /* Initial state - black text with black underline */
  color: var(--theme-black);
  text-decoration-color: var(--theme-black);
}

/* Light mode hover - change text and underline to lavender without background */
:root:not([data-theme='dark']) .wavy-text-link:hover {
  /* Keep text black when lavender background appears */
  color: var(--theme-black);
  text-decoration-color: var(--theme-black);
}

/* Dark mode styles */
[data-theme='dark'] .wavy-text-link {
  color: var(--theme-white);
  text-decoration-color: var(--theme-white);
}




/* Text color change on hover in dark mode */
[data-theme='dark'] .wavy-text-link:hover {
  color: var(--theme-black);
  text-decoration-color: var(--theme-black);
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-thickness: 2px;
}

/* Ensure the text-decoration is visible by adding more specificity */
.theme-active .wavy-text-link:hover,
.theme-container .wavy-text-link:hover {
  text-decoration-line: underline !important;
  text-decoration-style: wavy !important;
}

/* Wavy underline base styles */
.wavy-underline {
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

/* Wavy underline that appears on hover */
.wavy-underline-hover {
  position: relative;
}

/* Dark mode hover styles */
[data-theme='dark'] .wavy-underline-hover:hover {
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration-color: var(--theme-lime);
}

/* Light mode hover styles */
:root:not([data-theme='dark']) .wavy-underline-hover:hover {
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration-color: var(--theme-lavender);
}

/* Color variants */
.wavy-border-lime {
  text-decoration-color: var(--theme-lime);
}

.wavy-border-lavender {
  text-decoration-color: var(--theme-lavender);
}

.wavy-border-white {
  text-decoration-color: white;
}