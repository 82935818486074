@tailwind base;
@tailwind components;
@tailwind utilities;

#ct-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
}