/* Font Face Declarations */
/* Current fonts (2025) */
@font-face {
  font-family: 'Pack-Hard';
  src: url('./Pack-Hard.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pack-Soft';
  src: url('./Pack-Soft.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik-Bold';
  src: url('./aeonik-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik-Regular';
  src: url('./aeonik-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik-RegularItalic';
  src: url('./aeonik-regularitalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

/* Typography Classes - Scoped to 2025 theme */

/* Heading Styles */
.theme-active .teaser-h1 ,
.theme-container .teaser-h1 ,
.theme-scrollbars .teaser-h1 ,
.teaser-h1 {
  font-family: 'Pack-Soft', sans-serif;
  font-size: 132px;
  line-height: 100%;
  font-weight: normal;
}

.theme-active .teaser-mb-h1 ,
.theme-container .teaser-mb-h1 ,
.theme-scrollbars .teaser-mb-h1 ,
.teaser-mb-h1 {
  font-family: 'Pack-Soft', sans-serif;
  font-size: 60px;
  line-height: 100%;
  font-weight: normal;
}

.theme-active .heading-1,
.theme-container .heading-1,
.theme-scrollbars .heading-1,
.heading-1 {
  font-family: 'Pack-Soft', sans-serif;
  font-size: 110px;
  line-height: 130.909%;
  font-weight: normal;
}

.theme-active .heading-1-mobile,
.theme-container .heading-1-mobile,
.theme-scrollbars .heading-1-mobile,
.heading-1-mobile {
  font-family: 'Pack-Soft', sans-serif;
  font-size: 70px;
  line-height: 120%;
  font-weight: normal;
}

.theme-active .heading-2,
.theme-container .heading-2,
.theme-scrollbars .heading-2,
.heading-2 {
  font-family: 'Pack-Soft', sans-serif;
  font-size: 48px;
  line-height: 140%;
  font-weight: normal;
}

.theme-active .heading-2-caps,
.theme-container .heading-2-caps,
.theme-scrollbars .heading-2-caps,
.heading-2-caps {
  font-family: 'Pack-Soft', sans-serif;
  font-size: 48px;
  line-height: 110%;
  font-weight: normal;
  text-transform: uppercase;
}

.theme-active .heading-3,
.theme-container .heading-3,
.theme-scrollbars .heading-3,s
.heading-3 {
  font-family: 'Aeonik-Regular', sans-serif;
  font-size: 32px;
  line-height: 140%;
  margin: 0;
  font-weight: normal;
}

.theme-active .heading-3b,
.theme-container .heading-3b,
.theme-scrollbars .heading-3b,
.heading-3b {
  font-family: 'Aeonik-Bold', sans-serif;
  font-size: 32px;
  line-height: 140%;
  margin: 0;
  font-weight: normal;
}

.theme-active .heading-4-bold,
.theme-container .heading-4-bold,
.theme-scrollbars .heading-4-bold,
.heading-4 {
  font-family: 'Aeonik', sans-serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 700;
}
.theme-active .heading-4-bold,
.theme-container .heading-4-bold,
.theme-scrollbars .heading-4-bold,
.heading-4-bold {
  font-family: 'Aeonik-Bold', sans-serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: normal;
}
.theme-active .h4-dsk,
.theme-container .h4-dsk,
.theme-scrollbars .h4-dsk,
.h4-dsk {
  font-family: 'Aeonik-Bold', sans-serif;
  font-size: 18px;
  line-height: 140%;
  font-weight: normal;
}
.theme-active .h4-mbl,
.theme-container .h4-mbl,
.theme-scrollbars .h4-mbl,
.h4-mbl {
  font-family: 'Aeonik-Bold', sans-serif;
  font-size: 28px;
  line-height: 140%;
  font-weight: normal;
}
/* Body Text Styles */
.theme-active .body-text,
.theme-container .body-text,
.theme-scrollbars .body-text,
.body-text {
  font-family: 'Aeonik-Regular', sans-serif;
  font-size: 18px;
  line-height: 170%;
  font-weight: normal;
}

/* Body Text Styles */
.theme-active .body-text-bold,
.theme-container .body-text-bold,
.theme-scrollbars .body-text-bold,
.body-text-bold {
  font-family: 'Aeonik-Bold', sans-serif;
  font-size: 18px;
  line-height: 170%;
  font-weight: normal;
}

.theme-active .body-text-sm,
.theme-container .body-text-sm,
.theme-scrollbars .body-text-sm,
.body-text-sm {
  font-family: 'Aeonik-Regular', sans-serif;
  font-size: 16px;
  line-height: 120%;
  font-weight: normal;
}

.theme-active .body-text-sm-bold,
.theme-container .body-text-sm-bold,
.theme-scrollbars .body-text-sm-bold,
.body-text-sm-bold {
  font-family: 'Aeonik-Bold', sans-serif;
  font-size: 16px;
  line-height: 120%;
  font-weight: normal;
}

/* Link Styles */
.theme-active .link,
.theme-container .link,
.theme-scrollbars .link,
.link {
  font-family: 'Aeonik-Regular', sans-serif;
  font-size: 18px;
  line-height: 170%;
  font-weight: normal;
  /* text-decoration: underline; */
}

.theme-active .link-small,
.theme-container .link-small,
.theme-scrollbars .link-small,
.link-small {
  font-family: 'Aeonik-Regular', sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: normal;
  /* text-decoration: underline; */
}

/* Apply text rendering only to 2025 theme containers */
.theme-active,
.theme-container,
.theme-scrollbars {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}
