.box {
  padding-top: 90px;
  margin-bottom: -60px;
  background: #222222;
}

/* Additional 2025 Project Page styles */
.project-hero {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.project-content {
  &__image {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.01);
    }
  }

  &__video {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
}

.member-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  &__image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    filter: grayscale(100%);
    transition: filter 0.3s ease;

    &:hover {
      filter: grayscale(70%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

/* Dark mode adjustments */
.dark {
  .project-content__image,
  .project-content__video {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
}

@media (min-width: 768px) {
  .project-hero {
    height: 480px;
  }
}

@media (min-width: 1024px) {
  .project-hero {
    height: 580px;
  }
}