.item-enter {
  opacity: 0;
  transform: scale(0.9);
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* 2025 Search Page Specific Styles */
.search-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: 2rem auto;
}

.search-results {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .search-results {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .search-results {
    grid-template-columns: repeat(3, 1fr);
  }
}

.search-message {
  width: 100%;
  text-align: center;
  margin: 3rem 0;
  font-size: 1.5rem;
}

/* Dark mode adjustments */
.dark .search-message {
  color: white;
}