/* Styles for the green custom scrollbar */
.green-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #DAFF01 transparent; /* thumb and track color */
}

/* For Webkit browsers */
.green-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.green-scrollbar::-webkit-scrollbar-track {
  background: #DAFF01;
  border-radius: 10px;
}

.green-scrollbar::-webkit-scrollbar-thumb {
  background-color: #DAFF01;
  border-radius: 6px; /* Make thumb appear shorter for vertical scrollbar */
  border: 3px solid #DAFF01;
}

/* Styles for the blue custom scrollbar */
.blue-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #9167F0 transparent; /* thumb and track color */
}

/* For Webkit browsers */
.blue-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.blue-scrollbar::-webkit-scrollbar-track {
  background: #9167F0;
  border-radius: 10px;
}

.blue-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9167F0;
  border-radius: 6px; /* Make thumb appear shorter for vertical scrollbar */
  border: 3px solid #9167F0;
}

.no-scrollbar{
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;
}