.chevron {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.chevron-up {
  transform: rotate(-180deg);
}

.transition-max-height {
  transition: max-height 0.5s ease-in-out;
}

/* New styles for 2025 */
.section-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 1024px) {
    padding: 0 48px;
  }
}

.date-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.1;
}

.event-row {
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 1px dashed;
  padding: 16px 32px 24px;
  cursor: pointer;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.event-content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}