.box {
  padding-top: 90px;
  margin-bottom: -60px;
  background: #222222;
}

/* Additional 2025 Profile styles */
.profile-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.02);
}

.profile-social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.profile-social-links a {
  transition: opacity 0.2s ease;
}

.profile-social-links a:hover {
  opacity: 0.8;
}

.project-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.project-image-container {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-image:hover {
  transform: scale(1.05);
}

.project-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

/* Dark mode adjustments */
.dark .profile-image {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

@media (min-width: 1024px) {
  .project-card {
    grid-template-columns: 1fr 1fr;
  }
}